/** @jsx jsx */
import { jsx } from "theme-ui";
// import { graphql } from "gatsby";
import { Layout, Content } from "maker-ui";
import banner from "../../static/imgs/diwaliBanner.webp";
import "../../static/styles/diwali.css";
import options from "../mark-ui/options";
import theme from "../mark-ui/theme";
import HeaderComp from "../components/Header";
import FooterComp from "../components/Footer";
import Seo from "../components/seo";
import givingBackToNature from "../../static/imgs/givingBackToNature.webp";
import biodiversity from "../../static/imgs/biodiversity.webp";
import wellBeingThroughTrees from "../../static/imgs/wellBeingThroughTrees.webp";
import enduringLegacy from "../../static/imgs/enduringLegacy.webp";
import ContentHeader from "../components/campaignComponents/ContentHeader";
import Slider from "../components/campaignComponents/Slider";
import planting from "../../static/imgs/planting.webp";
import TreeDonationBox from "../components/campaignComponents/DonateBox/TreeDonationBox";

/* eslint-disable react/no-unknown-property */

const impactStories = [
  {
    id: 1,
    title: "Giving Back to Nature",
    description:
      "A small gesture to honor the countless gifts we receive from Mother Earth. There's no greater joy than knowing your actions are making a positive impact. Planting trees during special occasions is a wonderful way to show gratitude towards the environment and contribute to a healthier, greener future for generations to come.",
    image: givingBackToNature,
  },
  {
    id: 2,
    title: "Enhancing Well-being Through Trees",
    description:
      "Beyond purifying the air and regulating the climate, trees offer a soothing presence that benefits both mental and physical health. Research consistently shows that the presence of trees and green spaces can have a profound positive effect on a person's mental well-being. By planting trees, we contribute to healthier communities and a better quality of life for everyone.",
    image: wellBeingThroughTrees,
  },
  {
    id: 3,
    title: "Enduring Legacy",
    description:
      "Planting a tree creates a lasting positive impact. Trees offer a range of environmental and social benefits that continue well beyond our lifetime, sometimes lasting for centuries. Their contributions, from improving air quality to fostering community well-being, remain valuable as long as they thrive, leaving a legacy that future generations will benefit from.",
    image: enduringLegacy,
  },
  {
    id: 4,
    title: "Supporting Ecosystems and Biodiversity",
    description:
      "Trees are an essential component of a healthy ecosystem. They provide shelter and habitats for numerous species, including birds, insects, and small mammals, while their branches and leaves create microhabitats that sustain a variety of organisms. By planting a tree today, you help nurture biodiversity and contribute to a thriving ecosystem.",
    image: biodiversity,
  },
];

const benefits = [
  {
    id: 1,
    title: "🌿 Environmental Impact",
    description:
      "Each tree you donate helps absorb CO2, produce oxygen, and support biodiversity.",
  },
  {
    id: 2,
    title: "❤️ Community Well-being",
    description:
      "Trees improve air quality, provide shade, and create green spaces for communities to enjoy.",
  },
  {
    id: 3,
    title: "🌳 Lasting Legacy",
    description:
      "you’re giving a lasting gift of shade, beauty, and environmental support for future generations.",
  },
];

const DonateATreeForDiwali = () => {
  const defaultTrees = [10, 25, 50, 100, 500];
  const perTreeCost = 10;
  const config = {
    currency: "USD",
    donationId: 40,
    titletext: "Gift a Tree - Thanksgiving Celebration, USA",
    symbol: "$",
    initialAmount: 5,
  };

  return (
    <div className="diwali-donation-wrapper">
      <Layout theme={theme} options={options}>
        <Seo title="Gift a Tree - Thanksgiving Celebration | Heartfulness" />
        <HeaderComp headerCustomLogo="/imgs/heartfulness-white-logo.png" />
        <Content>
          <ContentHeader
            banner={banner}
            title="Grow a Tree, Honor Your Mom & Dear Ones"
            subTitle="A Perfect Thanksgiving Gift"
          />
          <div className="container">
            <div
              className="row my-5 diwali-donation-box-wrapper"
              sx={{
                display: "flex",
                "@media (max-width: 768px)": {
                  flexDirection: "column-reverse",
                },
              }}
            >
              <div className="col-lg-6 px-3 my-3">
                <h2
                  sx={{
                    color: "#1f4b72",
                    textAlign: "start",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                    fontStyle: "normal",
                    fontSize: "46px",
                    "@media (max-width: 768px)": {
                      fontSize: "1.8rem !important",
                    },
                  }}
                >
                  Celebrate{" "}
                  <span sx={{ color: "rgb(88 153 208)" }}>Thanksgiving</span> by
                  Planting Trees with Heartfulness
                </h2>
                <p className="mt-4 paragraph">
                  Let&apos;s go beyond words and give thanks in a way that truly
                  matters. On <b>November 28th</b>, plant a tree as a living
                  symbol of gratitude for everything nature provides us.
                </p>
                <p className="mt-4 paragraph">
                  Festivals celebrated by people everywhere can become as
                  impactful as Earth Day, Arbor Day, or World Environment Day,
                  which are widely known for inspiring tree planting and
                  environmental care. Any festive occasion can be a meaningful
                  time to give back to nature. Imagine gifting or dedicating
                  trees to loved ones during celebrations - it adds a personal,
                  lasting touch, as trees symbolize enduring love, hope, and the
                  promise of a greener future.
                </p>
                <p className="mt-4 paragraph">
                  As a focus on enabling retreats for meditation to help
                  purification of the mind, establishing spaces for purification
                  of the environment also becomes an important part of our
                  community. Having identified Kanha Shanti Vanam as the first
                  of the locations to help , this initiative will help members
                  of the community participate in the forestation effort at this
                  retreat where 100,000 people can meditate together surrounded
                  by a green forest.
                </p>
              </div>
              <div className="col-lg-6 my-3">
                <TreeDonationBox
                  config={config}
                  defaultTrees={defaultTrees}
                  perTreeCost={perTreeCost}
                  festival="Thanksgiving"
                />
              </div>
            </div>
          </div>
          <section className="container mb-5">
            <p className="plant-a-tree-text mb-4 paragraph">
              <h2
                sx={{
                  color: "#1f4b72",
                  textAlign: "start",
                  fontFamily: "Poppins",
                  fontWeight: "700",
                  fontStyle: "normal",
                  fontSize: "46px",
                  "@media (max-width: 768px)": {
                    fontSize: "1.8rem !important",
                  },
                }}
              >
                Plant a <span sx={{ color: "rgb(88 153 208)" }}>Tree</span> in
                Memory of a Loved One
              </h2>
            </p>
            <div
              className="row d-flex align-items-center justify-content-center flex-wrap plant-a-tree-wrapper"
              sx={{ backgroundColor: "white", border: "5px solid #E6F7EF" }}
            >
              <div className="col-12 col-xl-3 plant-a-tree text-column">
                <img
                  id="image"
                  src={planting}
                  alt="planting"
                  loading="lazy"
                  sx={{
                    width: "250px",
                    height: "auto",
                    "@media (max-width: 1200px)": {
                      width: "200px",
                    },
                  }}
                />
              </div>
              <div className="col-12 col-xl-9 text-column">
                <p sx={{ fontSize: "18px !important" }} className="paragraph">
                  Celebrate the legacy of your loved ones by giving back to
                  nature. By planting a tree in their memory, you contribute to
                  a greener planet and create a living tribute that will
                  flourish for generations to come.
                </p>

                <h5
                  className="mt-4"
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    fontStyle: "normal",
                    fontSize: "18px",
                  }}
                >
                  Where Your Tree Will Be Planted
                </h5>
                <p sx={{ fontSize: "18px !important" }} className="paragraph">
                  The tree will be planted in [Kanha Shantivanam / Other Sacred
                  Locations], where it will grow and thrive under the care of
                  our dedicated team, adding to the beautiful green landscapes
                  of these peaceful areas.
                </p>
              </div>
            </div>
          </section>
          <div className="diwali-slider">
            <Slider
              heading={
                <h2
                  className="sliders-heading"
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "700",
                    fontStyle: "normal",
                    fontSize: "46px",
                    "@media (max-width: 768px)": {
                      fontSize: "1.8rem !important",
                    },
                  }}
                >
                  Benefits Of <span className="highlightText"> Planting</span>{" "}
                  Trees During Thanksgiving
                </h2>
              }
              impactStories={impactStories}
            />
          </div>
          <div className="container ">
            <h2
              sx={{
                color: "#1f4b72",
                textAlign: "center",
                fontFamily: "Poppins",
                fontWeight: "700",
                fontStyle: "normal",
                fontSize: "46px",
                "@media (max-width: 768px)": {
                  fontSize: "1.8rem !important",
                },
              }}
            >
              The <span sx={{ color: "rgb(88 153 208)" }}>Impact</span> of Your
              Thanksgiving Tree Donation
            </h2>
            <div
              className="row my-5"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                "@media (max-width: 768px)": {
                  // flexDirection: "column-reverse",
                },
              }}
            >
              {benefits &&
                benefits?.map((i) => (
                  <div key={i?.id} className="col-md-6 col-lg-4 my-1">
                    <div className="benefits">
                      <div
                        sx={{
                          color: "#1f4b72",
                          fontFamily: "Poppins",
                          fontweight: "700",
                          fontStyle: "normal",
                          fontSize: "20px",
                        }}
                      >
                        <b>{i?.title}</b>
                      </div>
                      <p
                        className="paragraph"
                        sx={{
                          fontSize: "14px !important",
                          padding: "16px 16px 0 25px !important",
                        }}
                      >
                        {i?.description}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Content>
        <FooterComp
          customText={`Copyright to SMSF © ${new Date().getFullYear()}. All Rights Reserved`}
        />
      </Layout>
    </div>
  );
};

export default DonateATreeForDiwali;
